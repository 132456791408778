<template>
    <main id="forgottenPsw">
        <Header />
        <div class="hero-modal-page  d-flex justify-content-center align-items-center">
            <div class="hero-closed-gradient"></div>
            <div class="hero-closed-container container-xl">
                <!-- TITLE -->
                <div class="row">                    
                    <div class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                          <div class="card bg-white form-inner-container form-inner-container-shadow">
                                <div class="card-dialog">
                                    <div class="card-content text-center">    
                                        <ValidationObserver ref="observerFgPsw" v-slot="{ handleSubmit }">
                                            <form  @submit.prevent="handleSubmit(onSubmit)">            
                                                <img 
                                                    src="/imgs/new-password.svg" 
                                                    class="img-fluid mb-4" 
                                                    alt="új jelszó"
                                                >
                                                <h5 class="modal-title fs-3 fw-900 text-center w-100 my-5 text-uppercase">Elfelejtett jelszó</h5>
                                                <p class="text-center ps-4 pe-4">
                                                    Ha elfelejtetted a jelszavad, add meg az e-mail címed, amivel regisztráltál és küldünk egy e-mailt az új jelszó generáláshoz.
                                                </p>
                                                <div class="text-left">
                                                    <CustomInput
                                                        v-model="email"
                                                        class="mt-5 mb-2 text-left"
                                                        :label="'E-mail cím'"
                                                        name="email"
                                                        rules="required|email"                                    
                                                    />
                                                </div>
                                                <p class="alert font-13" :class="`alert-${formSubmitStatus}`" v-if="sendMessage">{{sendMessage}}</p>
                                                <button type="submit" class="btn btn-primary  mb-4">Elküld</button>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                </div>
                            </div>    
                    </div>
                </div>
            </div>
        </div>          
        <Footer />
    </main>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import CustomInput from '@/components/base/CustomInput.vue'
import { ValidationObserver } from 'vee-validate'

export default {
    components:{
        Header,
        Footer,
        CustomInput,
        ValidationObserver
    },
    data(){
        return{
            email: "",
            sendMessage: null,  
            formSubmitStatus:null          
        }
    },
    methods:{
        onSubmit(){         
            this.sendMessage = null
            this.formSubmitResult= null
            
            this.post('forgottenpassword/sendmail',{email:this.email }).then(()=>{
                this.email = "";
                this.formSubmitStatus = 'success'
                this.sendMessage = 'A jelszómódosításhoz szükséges adatokat elküldtük.'
                this.$nextTick(() => this.$refs.observerFgPsw.reset());
            }).catch((err)=>{
                this.goToError(err)  
            })
        },
        goToError(err){            
            
          this.setErrorMsgs(err, 'observerFgPsw').then(()=>{       
            console.log('hiba')         
              let firstError = document.querySelector('#forgottenPsw .input-error')
              this.scrollToElement(firstError, 80)       
          }).catch(()=>{
              //the error not bound to a field            
            this.formSubmitStatus = 'danger'
            this.sendMessage= "Hiba az üzenetküldés során, kérjük, próbálja meg újra."
          })                                   
        },
    }
}
</script>